import React from 'react';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate();

    return (
       <>
           <div className="mt-5 d-flex justify-content-end">
               <Link to={"/login"}>Login</Link>
           </div>
           <div className="mt-5">
               <h2>Register</h2>
               <Formik
                   initialValues={{ email: '', password: '', confirmPassword: '' }}
                   validationSchema={Yup.object({
                       name: Yup.string().required('The name field is required.'),
                       email: Yup.string().email('Invalid email address').required('The email field is required.'),
                       password: Yup.string().required('The password field is required.'),
                       password_confirmation: Yup.string()
                           .oneOf([Yup.ref('password'), null], 'Passwords must match')
                           .required('The confirmation password field is required.')
                   })}
                   onSubmit={(values, { setSubmitting }) => {
                       axios.post('https://api.smehr.pegotec.dev/api/auth/register', values)
                           .then(response => {
                               // handle successful registration
                               alert('Success to register a user')
                               navigate('/login');
                           })
                           .catch(error => {
                               // handle error
                               alert('There was an error logging in!' + error?.response?.data?.message)
                               console.error('There was an error registering!', error);
                           })
                           .finally(() => setSubmitting(false));
                   }}
               >
                   {({ isSubmitting }) => (
                       <Form>
                           <div className="mb-3">
                               <label htmlFor="name">Name</label>
                               <Field name="name" type="text" className="form-control" />
                               <ErrorMessage name="name" component="div" className="text-danger" />
                           </div>
                           <div className="mb-3">
                               <label htmlFor="email">Email Address</label>
                               <Field name="email" type="email" className="form-control" />
                               <ErrorMessage name="email" component="div" className="text-danger" />
                           </div>
                           <div className="mb-3">
                               <label htmlFor="password">Password</label>
                               <Field name="password" type="password" className="form-control" />
                               <ErrorMessage name="password" component="div" className="text-danger" />
                           </div>
                           <div className="mb-3">
                               <label htmlFor="password_confirmation">Confirm Password</label>
                               <Field name="password_confirmation" type="password" className="form-control" />
                               <ErrorMessage name="password" component="div" className="text-danger" />
                           </div>
                           <button type="submit" className="btn btn-primary" disabled={isSubmitting}>
                               Register
                           </button>
                       </Form>
                   )}
               </Formik>

           </div>
       </>
    );
};

export default Register;
